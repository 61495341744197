.admin-dashboard {
  display: flex;
}

.side-bar {
  margin-top: 70px;
  width: 180px;
  height: 100vh;
  background: #33333357;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 500;
}

.nav-link {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background: transparent;
  border: none;
  text-decoration: none;
  padding: 15px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background 0.3s;
}

.nav-link:hover,
.nav-link.active {
  background: #333333;
}

.content {
  margin-left: 180px;
  padding: 20px;
  width: calc(100% - 200px);
  margin-top: 20px;
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .side-bar {
    padding-top: 50px;
    background-color: #333333;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .side-bar.show {
    transform: translateX(0);
  }

  .content {
    margin-left: 0;
    width: 100%;
  }

  .menu-toggle {
    display: block;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    position: fixed;
    top: 80px;
    left: 20px;
    z-index: 1000;
  }
}

#small{
  font-size:10px;
  background:transparent;
}