/* Fonts imported */
@import url("https://fonts.googleapis.com/css2?family=Sigmar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Montserrat", sans-serif;
  src: url(./assets/fonts/Gilroy-Regular.woff);
}

body {
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif !important;
}

* {
  color: white;
  background-color: rgb(24, 24, 24);
}

/*SWAL DARK MODE*/

.swal2-popup {
  color: white !important;
  background-color: rgb(24, 24, 24) !important;
  border: none;
}

.swal2-title {
  color: white !important;
}

.swal2-html-container {
  color: white !important;
}

.swal2-close {
  color: white !important;
}

.swal2-styled.swal2-confirm {
  background-color: #4caf50 !important;
  /* Adjust as needed */
  border: none;
}

.swal2-styled.swal2-cancel {
  background-color: #f44336 !important;
  /* Adjust as needed */
  border: none;
}

.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  background-color: rgb(34, 34, 34) !important;
  /* Adjust as needed */
  color: white !important;
}

.swal2-icon {
  color: white !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border-color: #a5dc86 !important;
  /* Adjust as needed */
}

.swal2-icon.swal2-success .swal2-success-fix {
  background-color: rgb(24, 24, 24) !important;
  /* Match the background color */
}

.swal2-icon.swal2-success .swal2-success-line-tip,
.swal2-icon.swal2-success .swal2-success-line-long {
  background-color: #a5dc86 !important;
  /* Adjust the color of the tick */
  z-index: 10;
}

.swal2-progress-steps .swal2-progress-step {
  background-color: white !important;
  /* Adjust as needed */
}

.swal2-icon-content,
.swal2-x-mark {
  background-color: transparent !important;
}

/* SignupForm.css */

.shadow-box {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #333;
  margin-bottom: 10px;
  width: 22%;
  margin: 13px;
}

.signup-form-container {
  width: 400px;
  margin: 50px auto;
}

.logo {
  background-color: grey;
  margin: auto;
  padding: 15px;
  display: block;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px;
  background-color: #3ca151;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.userbutton {
  color: #3ca151;
  margin-left: 15px;
  cursor: pointer;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

button:disabled {
  opacity: 0.7;
  cursor: none;
}

button:not(:disabled):hover {
  background-color: #266533;
}

/* AccountBox.css */

.account-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 700px;
  padding: 20px;
  border-radius: 8px;
  margin: 20px auto;
}

.account-details {
  flex: 1;
}

.action-buttons button {
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

/* Navbar.css */

.navbar {
  background-color: #000000c5;
  backdrop-filter: blur(5px) !important;
  padding: 15px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 50px;
}

.nlogo {
  width: 40px;
  transform: scale(2.5);
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  color: #fff;
  /* Link text color */
  text-decoration: none;
  margin-right: 25px;
}

.nav-links a:hover {
  text-decoration: underline;
  /* Underline on hover */
}

/* InvestmentBox.css */

.investment-box {
  width: 500px;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: center;
}

th {
  background-color: #0f0f0f;
}

.table {
  scrollbar-width: none;
}

/*Admin*/
.admin-box {
  width: 70%;
  padding: 20px;
  margin: 20px auto;
  border-radius: 8px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  margin: 10px;
  /* Adjust the maximum width as needed */
}

.modal button {
  margin-left: 0px !important;
}

select {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.modal select {
  min-width: 250px;
  max-width: 250px;
}

#amount-input {
  min-width: 240px;
  max-width: 240px;
}

.close-button {
  background-color: transparent !important;
  color: #808080;
  border: none;
  cursor: pointer;
  font-size: 16px;
  align-self: flex-end;
  margin-bottom: 0px;
}

.close-button:hover {
  background-color: #218838 !important;
  color: white !important;
}

.radio-label {
  margin-bottom: 10px;
  margin-right: 10px;
}

.table-text {
  margin: 0;
  text-align: center;
  font-size: 10px;
  color: #444141;
}

/*Edit Modal Css*/
.modal-overlay-formal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-edit-formal {
  padding: 20px;
  border-radius: 8px;
  overflow: scroll;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 80%;
  max-width: 75%;
}

.close-formal {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

.form-formal {
  display: flex;
  flex-direction: column;
}

.label-formal {
  margin-bottom: 5px;
}

.input-formal[type="text"],
.input-formal[type="email"],
.select-formal {
  width: 400px;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.select-formal {
  height: 36px;
}

#today {
  font-size: 12px;
  opacity: 1;
  color: grey;
}

.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.profile-header {
  text-align: center;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-details {
  margin-top: 30px;
}

.profile-details h3 {
  margin-bottom: 10px;
}

.profile-details p {
  line-height: 1.6;
}

.profile-details ul {
  list-style-type: none;
  padding: 0;
}

.profile-details li {
  margin-bottom: 10px;
}

.profile-details li strong {
  font-weight: bold;
}

.navbar {
  background-color: #808080;
  padding: 15px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding-left: 200px;
  /* Adjust padding for larger screens */
  padding-right: 200px;
  /* Adjust padding for larger screens */
}

.logo-container img {
  max-width: 100px;
}

.nav-links {
  display: flex;
}

.menu-icon {
  display: none;
}

.nav-links a,
.nav-links button {
  color: #fff;
  text-decoration: none;
  padding: 10px;
}

.table {
  overflow-x: scroll;
}

@media screen and (max-width: 768px) {
  .profile-container {
    margin: 5px 10px;
  }

  .profile-header {
    overflow-wrap: break-word;
  }

  .navbar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .nav-links.show {
    display: flex;
    left: 0;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-links {
    flex-direction: column;
    position: absolute;
    top: 60px;
    background-color: #808080;
    left: -100%;
    width: 100%;
    transition: all 0.5s ease;
  }

  .nav-links a,
  .nav-links button {
    width: 100%;
    text-align: center;
    border-radius: 0px !important;
    margin-right: 0 !important;
  }

  .account-box {
    width: 75%;
    margin: auto;
    flex-direction: column;
  }

  #head_dash {
    margin: 20px !important;
    text-align: center;
  }

  #adminboxwrapper {
    flex-direction: column !important;
  }

  .box-width-correct {
    margin: 10px auto !important;
    width: 75%;
  }
}

@media screen and (max-width: 290px) {
  input,
  select {
    width: 50%;
  }
}

.hfA {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00000065;
  padding: 0 4%;
  z-index: 10;
}

.hfAa {
  width: 50px;
  height: 50px;
  background-image: url(../public/logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  object-fit: cover;
  background-color: transparent;
}

.hfAb {
  display: flex;
  align-items: center;
  color: #ffffffc9;
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
}

.hfAbA {
  padding: 10px;
  cursor: pointer;
  position: relative;
  color: white;
  text-decoration: none;
  background-color: transparent;
}

.hfAbA:hover {
  color: #90e172;
}

.hfAbA:hover .hfAbAa {
  display: flex;
  flex-direction: column;
}

.hfAbAa {
  display: none;
  position: absolute;
  top: 70px;
  right: 0;
  min-width: 300px;
  background-color: #000000c5 !important;
  backdrop-filter: blur(5px) !important;
  padding: 15px;
  border-radius: 7px;
  font-size: 20px;
  color: white;
}

.hfAbAaA {
  padding: 12px 20px;
  color: white;
  text-decoration: none;
}

.hfAbAaA:hover {
  color: #90e172;
}

.hfAbB {
  padding: 8px 20px;
  border-radius: 12px;
  margin-left: 10px;
  background-color: transparent;
  cursor: pointer;
  color: white;
  text-decoration: none;
  border: 1px solid rgb(216, 237, 209);
}

.hfAbC {
  display: none;
  position: relative;
  width: 30px;
  height: 30px;
  background-image: url(../public/drower.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-left: 20px;
}

.hfAbCa {
  position: absolute;
  top: 45px;
  right: 0;
  width: 300px;
  background-color: #000000cb;
  padding: 20px;
  border-radius: 7px;
}

.hfAbCaA {
  padding: 8px 0;
  font-size: 18px;
  background-color: transparent !important;
}

.newsletterSubmit {
  color: green;
  font-size: 24px;
  padding: 10px;
}

.dropdown {
  background-color: transparent;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #0f0f0fb9 !important;
  border-radius: 8px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  background-color: #0f0f0f36 !important;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content:hover .dropdown-content {
  display: block;
}

.dropdown-content a:hover .dropdown-content {
  display: block;
}

@media only screen and (max-width: 950px) {
  .hfAbA,
  #logbtn {
    display: none;
  }

  .dropdown {
    display: none;
  }

  .hfAbB {
    font-size: 12px;
  }

  .hfAbC {
    display: flex;
    background-color: transparent;
  }

  .hfAbAaA {
    padding: 10px 15px;
    font-size: 16px;
    background-color: transparent !important;
  }
}

/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
.hfD {
  background-color: black !important;
}

.hfDa {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  font-size: 36px;
  color: #262626;
}

.hfDaA {
  text-align: center;
  padding: 20px;
  font-weight: lighter;
  color: white;
}

.hfDaB {
  height: 50px;
  max-width: 700px;
  width: 90%;
  border-radius: 7px;
  background-color: #26262637 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hfDaBa {
  background-color: transparent;
  width: 100%;
  font-size: 24px;
  padding: 10px 30px;
  font-weight: 300;
  border: none;
}

.hfDaBa:focus {
  outline: none;
}

.hfDaBb {
  height: 100%;
  width: 200px;
  min-width: 200px;
  color: white;
  border-radius: 7px;
  background-color: #2f83f17c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  font-size: 24px;
}

/* ///////////////////////////////////////////////////////////// */
.hfDb {
  padding: 2.8% 5%;
  /* background-image: url(../assets/background/bg3.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: black !important;
}

.hfDbA {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid white;
  padding-bottom: 20px;
  background-color: black !important;
}

.hfDbAa {
  height: 70px;
  background-image: url(../public/logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: black !important;
}

.hfDbAb {
  font-size: 23px;
  color: white;
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  background-color: black !important;
}

.hfDbAbA {
  width: 220px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.hfDbAaBlack {
  background-color: black !important;
}

.hfdab .hfDbAbB {
  width: 350px;
  cursor: pointer;
  background-color: black !important;
}

.hfDbAbC {
  width: 220px;
  text-align: end;
  cursor: pointer;
}

.hfDbB {
  padding: 30px 0;
  display: flex;
  background-color: black !important;
}

.hfDbBa,
.hfDbBb,
.hfDbBc,
.hfDbBd,
.hfDbBe,
.hfDbBf {
  width: 30px;
  height: 30px;
  margin-right: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  background-color: black !important;
}

@media only screen and (max-width: 950px) {
  .hfDaA {
    font-size: 25px;
  }

  .hfDaB {
    height: 45px;
  }

  .hfDaBa {
    background-color: transparent;
    width: 100%;
    font-size: 18px;
    padding: 5px 20px;
  }

  .hfDaBb {
    min-width: 100px;
    font-size: 18px;
  }

  .hfDbA {
    flex-direction: column;
  }

  .hfDbAb {
    font-size: 12px;
    padding: 6px 0;
  }

  .hfDbAbA {
    width: 80px;
  }

  .hfDbAbB {
    width: 180px;
  }

  .hfDbAbC {
    width: 80px;
  }

  .hfDbA {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #lower-footer {
    margin-top: 35px;
  }
}

.hfDbBa {
  size: 30;
  color: grey;
  cursor: pointer;
  margin-right: 25px;
}

.hfDbBa:hover {
  color: darkgrey;
}

.privacy-policy {
  padding: 25px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
}

.pagination li.active {
  font-weight: bold;
}

#upper-box {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 530px) {
  #upper-box {
    flex-direction: column;
  }
}

.cookie-consent-container,
.cookie-consent-container div {
  background-color: #2c2c2c !important;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.cookie-consent-button {
  background-color: #3ca151 !important;
  color: #ffffff !important;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px !important;
}

.cookie-decline-button {
  background-color: #f77269;
  color: #ffffff;
  border: none;
  border-radius: 5px !important;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

/*Bulk Registration*/
.bulk-registration {
  max-width: 1000px;
  margin: 50px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bulk-registration h2 {
  text-align: center;
  margin-bottom: 20px;
}

.bulk-registration form {
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bulk-registration table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.bulk-registration th,
.bulk-registration td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.bulk-registration input,
.bulk-registration select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.bulk-registration button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bulk-registration button[type="button"] {
  background-color: #007bff;
  color: white;
}

.bulk-registration button[type="button"]:hover {
  background-color: #0056b3;
}

.bulk-registration button[type="submit"] {
  background-color: #28a745;
  color: white;
}

.bulk-registration button[type="submit"]:hover {
  background-color: #218838;
}

.results {
  margin-top: 20px;
}

.results table {
  width: 100%;
  border-collapse: collapse;
}

.results th,
.results td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

/* Global Styles */

.pcontainer {
  max-width: 1200px;
  margin: 80px auto;
  padding: 20px;
}

.profile-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-header img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.profile-content {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: start;
}

.profile-info {
  flex-basis: 75%;
}

.profile-info h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.profile-info p {
  font-size: 14px;
  color: lightgray;
  font-weight: 700;
  margin-top: 0px;
  padding: 0px;
  margin-bottom: 10px;
}

.profile-info button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
}

.profile-info button:hover {
  background-color: #3e8e41;
}

.profile-about {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 25px;
  flex-basis: 100%;
  margin: 40px 0px;
}

.profile-about h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.profile-about p {
  font-size: 14px;
  color: white;
  margin-bottom: 10px;
  font-weight: 300;
}

.featured-blogs {
  margin-top: 40px;
}

.featured-blogs h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.blog-post {
  margin-bottom: 20px;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.blog-post:last-child {
  border-bottom: none;
}

.new-login-container {
  background: url("https://framerusercontent.com/images/Pgpbl5IUil2FjuOWIHtib87UbDY.jpg");
  height: 100%;
  padding-bottom: 50px;
  padding-top: 250px;
  backdrop-filter: opacity(0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

.login-video-box,
.login-form-box {
  background-color: transparent;
  height: min-content;
  border-radius: 10px;
}

.divideLine {
  width: 3px;
  background-color: rgb(105, 104, 104);
  height: 50%;
}

.login-form-box {
  margin-right: 50px;
}

.login-video-box iframe {
  border-radius: 10px;
  min-width: 350px;
}

.login-form-box form,
.login-form-box p {
  background-color: transparent;
}

.login-form-box form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form-box p {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.login-form-box button {
  font-size: 22px;
  border-radius: 8px;
  width: 117% !important;
}

#separatorLogin {
  font-size: 18px;
}

.h-full {
  padding-top: 30px;
  height: 100vh;
  padding-bottom: 70px;
}

.new-login-container form select {
  width: 110% !important;
  font-size: 22px;
}

@media screen and (max-width: 1040px) {
  .new-login-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 80px;
  }

  .new-login-container .login-form-box {
    margin-bottom: 30px;
    margin-right: 0px;
  }

  .new-login-container input {
    width: 100%;
  }

  .new-login-container .login-video-box iframe {
    width: 90%;
  }

  .login-form-box {
    margin-right: 0px;
  }

  .divideLine {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .new-login-container form {
    width: 80%;
    margin: auto;
  }

  .new-login-container form select {
    width: 119%;
  }

  #popOver,
  #popoverbtn {
    display: none !important;
  }

  .googleAuthBox {
    width: 90% !important;
    margin: auto !important;
  }
}

#privacyCheckBox {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: baseline;
}

#privacyCheckBox p,
#privacyCheckBox a {
  font-size: 15px;
  text-wrap: nowrap !important;
  background-color: transparent !important;
}

.ant-popover .ant-popover-inner {
  background-color: transparent;
}

.ant-popover .ant-popover-arrow {
  background-color: transparent;
}

.ql-editor {
  overflow: auto;
  font-weight: normal;
}

.ql-toolbar .ql-picker.ql-expanded .ql-picker-options {
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
}

.ql-toolbar {
  border: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
}

.ql-container {
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.ql-picker-label {
  padding: 5px;
  cursor: pointer;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor p {
  font-weight: normal;
}

.ql-size,
.ql-font {
  font-family: inherit;
  font-size: inherit;
}

/* CurrencyTable.css */
.currency-table {
  font-family: Arial, sans-serif;
  width: 50%;
  margin: 100px auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.currency-table h2 {
  text-align: center;
  padding: 10px;
  margin: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.currency-table table {
  border-collapse: collapse;
}

.currency-table th,
.currency-table td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

/* UserCard.css */
#cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
}

.user-card-content *,
#sendBoxOpen *,
#sendBoxOpen {
  background-color: #303030;
}

#sendBoxOpen {
  padding: 20px;
  border-radius: 8px;
  width: 250px;
  margin: 25px;
  margin-top: 70px;
}

.user-card-content button,
#sendBoxOpen button {
  margin-top: 25px;
  padding: 10px;
  background-color: #3ca151;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.user-card-content {
  background-color: #303030 !important;
  height: 280px;
  width: 280px;
  margin: 10px;
  border-radius: 5px;
  padding: 20px;
}

.user-card-logo img {
  height: 50px;
}

.ref-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.googleAuthBox {
  width: 100%;
  border: none !important;
  background-color: transparent;
  border-radius: 12px;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 50px;
  margin-right: 15px !important;
}

.googleAuthBox span {
  color: #000;
}

.googleAuthBox * {
  background-color: transparent !important;
  border: none !important;
}

.table {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #ddd #111;
}

.table::-webkit-scrollbar {
  height: 10px;
}

.table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.sticky-page {
  position: sticky;
  top: 0;
  float: left;
}

.referral-code {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.referral-code input {
  padding: 8px;
  margin-bottom: 16px;
  border: 2px solid #111;
  border-radius: 4px;
  width: 65%;
  margin-right: 20px;
  border-radius: 40px;
}

.page-item .page-link {
  font-size: 15px;
}

.page-item.active .page-link {
  font-weight: 700;
  text-decoration: underline;
  background-color: #135221;
}

@media screen and (max-width: 600px) {
  #community-container {
    flex-direction: column-reverse !important;
    align-items: center !important;
  }

  .sticky-page {
    position: relative;
    top: 0;
    float: left;
  }

  .ref-parent {
    flex-direction: column;
  }

  .referral-code {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
  }
}

.filterBoxCom {
  text-align: center;
}

.filterBoxCom input {
  padding: 10px;
  margin-right: 10px;
}

.circular-image {
  height: 60px !important;
  width: 60px !important;
  border-radius: 50%;
  object-fit: cover;
}

#privacyCheckBox input {
  font-size: 10px !important;
  padding: 10px 0px !important;
}

.parent-reg {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* changes aman */
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  background: url("https://framerusercontent.com/images/Pgpbl5IUil2FjuOWIHtib87UbDY.jpg");
  height: 100%;
  padding-bottom: 50px;
  padding-top: 250px;
  backdrop-filter: opacity(0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ch-cotainer {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: transparent;
}
.ch-cotainer.form {
  width: 30%;
}
.ch-cotainer select,
.ch-cotainer input {
  width: 100%;
  font-size: 22px;
}

.ch-cotainer input {
  width: 96%;
  padding: 8px;
  font-size: 22px;
}

.login-form-box {
  margin-right: 0px;
}

.login-form-box form {
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.ch-cotainer .login-form-box button {
  font-size: 22px;
  border-radius: 8px;
  width: 100% !important;
}

@media screen and (max-width: 1200px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    padding-top: 100px;
  }
  .ch-cotainer {
    width: 80%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: transparent;
  }

  .ch-cotainer iframe {
    width: 100%;
    margin-left: 0%;
    margin-top: 50px;
  }
  .ch-cotainer.form {
    width: 100%;
  }
  .new-login-container {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-bottom: 80px;
  }
}

#blue-btn {
  background-color: #007bff;
}

#blue-btn:hover {
  background-color: #0056b3;
}
.rewards {
  width: 100%;
  padding: 0px;
  margin-left: 0%;
  margin-top: 0px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20 !important;
  position: fixed;
  border: solid rgba(0, 0, 0, 0.1) 0px !important;
  padding: 200px;
}
.reward-bg {
  width: 40%;
  margin-left: 20%;
  background-color: transparent;
  padding: 1px;
  background-image: url("../src//assets/reward.svg");
  border-radius: 10px;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .reward-bg {
    width: 60%;
    height: 350px;
  }
  .reward-bg h2 {
    background-color: transparent;
    color: black;
    margin-top: 10%;
  }
}

/* Large screens (desktops) */
@media (min-width: 1024px) {
  .reward-bg {
    width: 40%;
    height: 400px;
  }
  .reward-bg h2 {
    background-color: transparent;
    color: black;
    margin-top: 15%;
  }
}

/* Extra large screens (large desktops, TVs) */
@media (min-width: 1440px) {
  .reward-bg {
    width: 35%;
    height: 450px;
  }
  .reward-bg h2 {
    background-color: transparent;
    color: black;
    margin-top: 20%;
  }
}

.reward-bg h2 {
  background-color: transparent;
  color: black;
  margin-top: 8%;
}
.reward-bg p {
  background-color: transparent;
  font-weight: 400;
  color: black;
  font-size: 14px;
  line-height: 0px;
}
#font {
  font-size: 22px;
  background-color: transparent;
  font-weight: 600;
  color: black;
}

#first {
  background-color: transparent;
  font-weight: 600 !important;
  color: black;
  font-size: 16px;
  line-height: 0px;
}
.reward-bg button {
  margin-top: 20px;
  background: #466747 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 11px;
  width: 192px;
  font-size: 16px;
  font-weight: 600;
}
.close {
  background-color: transparent;
  float: right;
}
.close img {
  background-color: transparent;
  width: 30px;
}
button.close:hover {
  background-color: transparent;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: transparent !important;
}

.bg-transparent h1,
.bg-transparent div {
  background-color: rgb(44 44 44);
}

.buy-dm-card {
  background-color: "#2c2c2c";

  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 10px;
}

.buy-org {
  width: 95%;
  border-radius: 12px;
  border: 2px black solid;
  padding: 2px 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  justify-content: space-between;
}

.buy-org-img-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.badge {
  border-radius: 12px;
  padding: 6px 12px;
}

.div-center {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  padding: 0;
}

.buy-dm-card label {
  width: 100%;
}

.buy-dm-card input {
  margin-top: 4px;
  width: 98%;
}
.buy-dm-card textarea {
  margin-top: 4px;
  width: 100%;
}

.buy-dm-but {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.gift-card-button-form {
  padding: 12px 16px;
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #333333;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.gift-card-button-form-light {
  padding: 12px 16px;
  background-color: #FFF7D1;
  color: #333333;
  border: 1px solid #FFF7D1;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.gift-card-button-form:hover {
  background-color: #1f1f1f !important;
  color: #00ff99;
}

.gift-card-button-form.active {
  background-color: #1f1f1f;
  color: #00ff99;
}
.gift-card-button-form-light:hover {
  background-color: #A0D683 !important;
  color: #333333;
}

.gift-card-button-form-light.active {
  background-color: #A0D683;
  color: #333333;
}
.gift-card-container {
  display: flex;
  padding: 80px 20px;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.item-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.gift-card-heading-cont {
  padding: 20px 200px;
}
.gift-card-form {
  width: 50%;
  padding: 20px;
  color: #000;
  border-radius: 20px;
  margin: 10px 90px;
  background-color: rgb(212, 245, 211);
}
.width-full {
  width: 100%;
}
.w-screen {
  width: 100vw;
}
.p-10 {
  padding: 100px;
}
.gift-form-main {
  display: flex;
  flex-direction: column;
  background-color: rgb(212, 245, 211) !important;
}
.gift-card-input {
  color: black !important;
  background-color: white !important;
}
h2,
h1,
h3,
h4,
h5,
h6,
p,
span,
label {
  background-color: transparent;
}
.gift-card-button.active {
  background-color: green;
  color: #fff;
}
.giving-tuesday-container {
  padding: 20px;
  width: 50%;
}
.giving-tuesday-container {
  padding: 20px;
  width: 70;
  margin: 100px;
}
